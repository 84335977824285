import React from 'react'

const VideoSection = () => {
    return (
        <div>
            <video autoPlay muted loop className="w-100">
                <source src="/assets/videos/hero.mp4" type="video/mp4" />
            </video>
        </div>
    )
}

export default VideoSection