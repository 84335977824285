import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MobileSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
    };

    // const slides = [
    //     { id: 1, content: "Slide 1" },
    //     { id: 2, content: "Slide 2" },
    //     { id: 3, content: "Slide 3" },
    // ];

    return (
        <div className="slider-container" style={{ width: "100%", margin: "0 auto" }}>
            <Slider {...settings}>
                <div className="item border-2 h-100vh">
                    <div className="position-relative z-300 border-right-2 linear-border">
                        <img
                            src="assets/img/hero-1.jpg"
                            className="w-full no-effects img-fluid h-100vh cover"
                            alt="hero"
                        />
                        {/* <div className="field-name">Software Development Solutions</div> */}

                        <div className="info-card card">
                            <div className="card-body">
                                <div className="text-center">
                                    <h5 className="card-title">
                                        Software Development Solutions
                                    </h5>
                                    <p className="card-text">
                                        We offer tailored software development solutions to meet our clients' unique needs. Our team of experts is here to help you create the perfect software for your business.
                                    </p>
                                    {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item border-2 h-100vh">
                    <div className="position-relative z-300 border-right-2 linear-border">
                        <img
                            src="assets/img/hero-2.jpg"
                            className="w-full no-effects img-fluid h-100vh cover"
                            alt="hero"
                        />
                        {/* <div className="field-name">Software Development Solutions</div> */}

                        <div className="info-card card">
                            <div className="card-body">
                                <div className="text-center">
                                    <h5 className="card-title">
                                        Internet of Things Solutions
                                    </h5>
                                    <p className="card-text">
                                        We deliver personalized Internet of Things solutions designed to fit our clients' specific requirements. Our skilled team is ready to support you in developing the right software for your needs.
                                    </p>
                                    {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item border-2 h-100vh">
                    <div className="position-relative z-300 border-right-2 linear-border">
                        <img
                            src="assets/img/hero-3.jpg"
                            className="w-full no-effects img-fluid h-100vh cover"
                            alt="hero"
                        />
                        {/* <div className="field-name">Software Development Solutions</div> */}

                        <div className="info-card card">
                            <div className="card-body">
                                <div className="text-center">
                                    <h5 className="card-title">
                                        AI & ML Development Solutions
                                    </h5>
                                    <p className="card-text">
                                        We specialize in crafting custom AI and ML solutions designed to suit our clients' specific requirements. Let our team of experts help you develop the software you need.
                                    </p>
                                    {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item border-2 h-100vh">
                    {/* overlay images */}
                    <div className="position-relative z-300 border-right-2 linear-border">
                        <img
                            src="assets/img/hero-4.jpg"
                            className="w-full no-effects img-fluid h-100vh cover"
                            alt="hero"
                        />
                        {/* <div className="field-name">Software Development Solutions</div> */}

                        <div className="info-card card">
                            <div className="card-body">
                                <div className="text-center">
                                    <h5 className="card-title">
                                        Factory Automation Solutions
                                    </h5>
                                    <p className="card-text font-weight-semibold">
                                        We deliver personalized factory automation solutions designed to streamline your operations. Our team of experts will work with you to build the perfect software for your needs.
                                    </p>
                                    {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default MobileSlider;
