import React from "react";
// import SingleBlog from "../components/blogs/SingleBlog";
import Footer from "../components/layout/footer/Footer";
import NavBar1 from "../components/layout/header/NavBar1";
import Layout from "../components/layout/Layout";

// import styles from "./BlogDetails.module.css";
import Loader from "../components/loader";
import CareerDetails from "../components/career/CareerDetails";

export default function Careers() {
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);
  return (
    <Layout>
      {loader && <Loader />}
      <NavBar1 darkBg />
      <CareerDetails />
      <Footer />
    </Layout>
  );
}
