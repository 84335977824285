import AppServices from "../../components/app-services";
// import styles from "./index-4.module.css";
import React from "react";
import MobileSlider from "../../components/mobile-slider/MobileSlider";
// import hero1 from "@assets/img/hero-1.jpg";

export default function HeroFougito() {

  const [prevImage, setPrevImage] = React.useState(0);
  const [currentImage, setCurrentImage] = React.useState(0);

  const images = [
    "assets/img/hero-1.jpg",
    "assets/img/hero-2.jpg",
    "assets/img/hero-3.jpg",
    "assets/img/hero-4.jpg",
  ];

  return (
    <div id="top">
      <div className="desktop-hero" >
        <div className="overflow-hidden position-relative">
          {/* <div className="video-overlay"></div> */}
          <section
            id="home"
            className={`video-promo ptb-100 h-screen position-relative px-0`}
            style={{
              background:
                //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url('assets/img/hero-new.png') no-repeat center center / cover",
                "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6))",
            }}
          >

            {/* absolute bg image */}
            <div className="absolute-bg position-absolute top-0 left-0 w-100 h-100">
              <img
                src={images[prevImage]}
                className="w-100 no-effects img-fluid h-100vh cover"
                alt="hero"
              />
            </div>
            {/* absolute bg image */}

            {/* grid 4 */}
            <div className="grid-4 w-100 overflow-hidden">
              <div className="item border-2 h-100vh" onMouseEnter={() => {
                setPrevImage(currentImage);
                setCurrentImage(0);
              }
              }>
                {/* overlay images */}
                <img
                  src={images[0]}

                  className="w-full no-effects img-fluid h-100vh cover overlay-img"
                  alt="hero"
                />
                <div className="position-relative z-300 border-right-2 linear-border">
                  <img
                    src="assets/img/hero-1.jpg"
                    className="w-full no-effects img-fluid h-100vh cover bnw"
                    alt="hero"
                  />
                  <div className="field-name">Software Development Solutions</div>

                  <div className="info-card card">
                    <div className="card-body">
                      <div className="text-center">
                        <h5 className="card-title">
                          Software Development Solutions
                        </h5>
                        <p className="card-text">
                          We offer tailored software development solutions to meet our clients' unique needs. Our team of experts is here to help you create the perfect software for your business.
                        </p>
                        {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item border-2 border-white h-100vh" onMouseEnter={() => {
                setPrevImage(currentImage);
                setCurrentImage(1);
              }
              }>
                {/* overlay images */}
                <img
                  src={images[1]}

                  className="w-full no-effects img-fluid h-100vh cover overlay-img hero-2"
                  alt="hero"
                />
                <div className="position-relative z-300 border-right-2 linear-border">
                  <img
                    src="assets/img/hero-2-sm.jpg"
                    className="w-full no-effects img-fluid h-100vh cover bnw hero-two-img"
                    alt="hero"
                  />
                  <div className="field-name">Internet of Things Solutions</div>

                  <div className="info-card card">
                    <div className="card-body">
                      <div className="text-center">
                        <h5 className="card-title">
                          Internet of Things Solutions
                        </h5>
                        <p className="card-text">
                          We deliver personalized Internet of Things solutions designed to fit our clients' specific requirements. Our skilled team is ready to support you in developing the right software for your needs.
                        </p>
                        {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item border-2 border-white h-100vh" onMouseEnter={() => {
                setPrevImage(currentImage);
                setCurrentImage(2);
              }
              }>
                {/* overlay images */}
                <img
                  src={images[2]}
                  className="w-full no-effects img-fluid h-100vh cover overlay-img"
                  alt="hero"
                />
                <div className="position-relative z-300 border-right-2 linear-border">
                  <img
                    src="assets/img/hero-3-sm.jpg"
                    className="w-full no-effects img-fluid h-100vh cover bnw"
                    alt="hero"
                  />
                  <div className="field-name">AI & ML Development Solutions</div>

                  <div className="info-card card">
                    <div className="card-body">
                      <div className="text-center">
                        <h5 className="card-title">
                          AI & ML Development Solutions
                        </h5>
                        <p className="card-text">
                          We specialize in crafting custom AI and ML solutions designed to suit our clients' specific requirements. Let our team of experts help you develop the software you need.
                        </p>
                        {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item h-100vh" onMouseEnter={() => {
                setPrevImage(currentImage);
                setCurrentImage(3);
              }
              }>
                {/* overlay images */}
                <img
                  src={images[3]}
                  className="w-full no-effects img-fluid h-100vh cover overlay-img"
                  alt="hero"
                />
                <div className="position-relative z-300 border-right-2 border-white">
                  <img
                    src="assets/img/hero-4.jpg"
                    className="w-full no-effects img-fluid h-100vh cover bnw"
                    alt="hero"
                  />
                  <div className="field-name">Factory Automation Solutions</div>

                  <div className="info-card card">
                    <div className="card-body">
                      <div className="text-center">
                        <h5 className="card-title">
                          Factory Automation Solutions
                        </h5>
                        <p className="card-text">
                          We deliver personalized factory automation solutions designed to streamline your operations. Our team of experts will work with you to build the perfect software for your needs.
                        </p>
                        {/* <a href="/" className="btn btn-primary">
                        Learn More
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* grid 4 */}
          </section>
        </div>
        <AppServices />
      </div>

      <div className="mobile-hero">
        <MobileSlider />
        <AppServices />
      </div>
    </div>
  );
}
